import React from "react";

import Ready from "../ready/ready";
// import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Exam from "../exam/exam";
import Latest from "../latest/latest";
// import { Link } from "gatsby"
import UseCaseTopBanner from "../top_banner/product_top_banner";
import Charts from "../charts/charts";
import Logos from "../logos-section/logos";
import { useTranslation } from 'react-i18next';

const Examens = () => {
  const { t } =useTranslation();
  const exams = [
    {
      title: `${t('a_secure_system')}`,
      description:
        `${t('expand_your_teaching')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/graph_board.svg",
    },
    {
      title: `${t('a_hyper_flexible_solution')}`,
      description:
        `${t('offer_your_student_and_yourself')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/infinity_with_peoples_inside.svg",
    },
    {
      title: `${t('lms_integration')}`,
      description:
        `${t('our_secure_system_integrates_seamlessly')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/people_connecting_switch.svg",
    },
    {
      title: `${t('5_star_student_experience')}`,
      description:
        `${t("24_support_and_a_calming")}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/five_stars_screen_with_people.svg",
    },
  ];
  const LatestData = {
    title1: `${t('discover_our')}`,
    title2: "dernière publication",
    description: `${t('in_partnership_with_edhec')}`,
    btnText: `${t('to_consult')}`,
  };

  const ChartData={title:["NEOMA BS utilise Mereos","pour ses formations managériales"],contexte:`${t('neoma_business_school_provides')}`,Résultats:[{heading:`2 ${t('days')}`,desc:`${t('to_start_the_first_exam_session')}`},{heading:"1 200 ",desc:`${t('candidates_evaluated_simultaneously_on_mereos')}`},{heading:"4,9",desc:`${t('average_score_given_by_candidates_to_mereos')}`}],points:[`${t('scalability')}`,`${t('monitoring')}`,`${t('autonomy')}`,`${t('flexibility')}`]}

  // const breakpoints = useBreakpoint();
  return (
    <div className={`conatiner font-sans`}>
      <UseCaseTopBanner
        pageTitle={t('exams')}
        title={t('solution_that_preserves_your_reputation')}
        description={t('higher_education_the_reputation')}
        imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/examens.svg"
        rightArrowImageSrc="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_right_white_icon.svg"
        leftButton={{
          text: `${t("free_trials")}`,
          href: "/free-trial",
        }}
        rightButton={{
          text: `Demo >`,
          href: "/demo",
        }}
        bannerType="useCase"
      />

      {/* logos  */}
      <Logos />

      <div className={`mx-auto w-11/12 lg:w-5/6 `}>
        <div className="my-20">
          <Exam Data={exams} title={t('why_is_mereos_right_for_you')} />
        </div>
      </div>

      <div className={`mx-auto w-11/12 lg:w-5/6`}>
        {/* Latest from Source */}
        <Latest data={LatestData} />

        {/* Charts */}
        <Charts ChartData={ChartData}/>
      </div>

      {/* Ready */}
      <div className="flex flex-row mt-10 mb-20">
        <img
          alt="right star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="hidden md:block self-end"
        />
        <div className="mt-10 mx-auto w-11/12 lg:w-5/6">
          <Ready />
        </div>

        <img
          alt="left star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="hidden md:block self-start"
        />
      </div>
    </div>
  );
};

export default Examens;
