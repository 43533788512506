import * as React from "react";

import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import ExamensPage from "../components/use_case/Examens";
import { useTranslation } from 'react-i18next';

const Examens = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title="Examens"
        description={t('preserve_the_integrity_of_your_students')}
      />
      <Layout>
        <ExamensPage />
      </Layout>
    </>
  );
};

export default Examens;
